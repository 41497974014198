<template>
  <div class="publish-area-container">
    <div class="transfer-left-container">
      <div class="select-all">
        <el-checkbox v-model="selectAll.left" @change="handleLeftTreeSelectAll"
          >待选国家/地区</el-checkbox
        >
        <div class="select-all-numbers">
          {{ selectNumber.left }}/{{ totalArea }}
        </div>
      </div>
      <el-input
        v-model="search.left"
        placeholder="请输入要搜索的国家/地区"
        size="normal"
        clearable
      >
        <el-button
          slot="suffix"
          type="text"
          icon="el-icon-search"
          @click="onSearch('left')"
        ></el-button>
      </el-input>
      <el-tree
        ref="treeLeft"
        :data="publishAreaTree"
        node-key="country_id"
        :props="defaultProps"
        show-checkbox
        @check="checkChangeLeft"
        :filter-node-method="filterTreeNode"
      ></el-tree>
    </div>
    <div class="transfer-center-container">
      <el-button
        type="primary"
        :disabled="selectNumber.right === 0"
        @click="transferToLeft"
      >
        <i class="el-icon-arrow-left"></i>
      </el-button>
      <el-button
        type="primary"
        :disabled="selectNumber.left === 0"
        @click="transferToRight"
      >
        <i class="el-icon-arrow-right"></i>
      </el-button>
    </div>
    <div class="transfer-right-container">
      <div class="select-all">
        <el-checkbox
          v-model="selectAll.right"
          @change="handleRightTreeSelectAll"
          >已选国家/地区</el-checkbox
        >
        <div class="select-all-numbers">
          {{ selectNumber.right }}/{{ totalAreaRight }}
        </div>
      </div>
      <el-input
        v-model="search.right"
        placeholder="请输入要搜索的国家/地区"
        size="normal"
        clearable
      >
        <el-button
          slot="suffix"
          type="text"
          icon="el-icon-search"
          @click="onSearch('right')"
        ></el-button>
      </el-input>
      <el-tree
        ref="treeRight"
        :data="area"
        node-key="country_id"
        :props="defaultProps"
        default-expand-all
        show-checkbox
        @check="checkChangeRight"
        :filter-node-method="filterTreeNode"
      ></el-tree>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    area: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: {
        left: "",
        right: "",
      },
      selectAll: {
        left: false,
        right: false,
      },
      selectNumber: {
        left: 0,
        right: 0,
      },
      defaultProps: {
        children: "list",
        label: function(data, node) {
          return data.area || data.country_name_cn;
        },
      },
    };
  },
  updated() {
    console.log(1);
  },
  methods: {
    handleRightTreeSelectAll() {
      if (this.selectAll.right) {
        const keys = this.getTreeKeys(this.area);
        this.$refs.treeRight.setCheckedKeys(keys);
        this.selectNumber.right = keys.filter((i) => +i > 10).length;
      } else {
        this.$refs.treeRight.setCheckedKeys([]);
        this.selectNumber.right = 0;
      }
    },
    handleLeftTreeSelectAll() {
      if (this.selectAll.left) {
        const keys = this.getFilterTreeKeys(
          this.publishAreaTree,
          this.search.left
        );
        this.$refs.treeLeft.setCheckedKeys(keys);
        this.selectNumber.left = keys.filter((i) => +i > 10).length;
      } else {
        this.$refs.treeLeft.setCheckedKeys([]);
        this.selectNumber.left = 0;
      }
    },
    onSearch(way) {
      if (way === "left") {
        this.$refs.treeLeft.filter(this.search.left);
      }
      if (way === "right") {
        this.$refs.treeRight.filter(this.search.right);
      }
    },
    filterTreeNode(value, data) {
      if (value === "") {
        return true;
      }
      if (data.list) {
        return !!data.list.find((i) => i.country_name_cn.indexOf(value) !== -1);
      }
      if (data.country_name_cn) {
        return data.country_name_cn.indexOf(value) !== -1;
      }
      return true;
    },
    // 过滤得到叶子节点，即国家。
    filterCountry(item) {
      console.log(+item.country_id > 10);
      return +item.country_id > 10;
    },
    mapCountry(filterFunction) {
      return (item) => {
        return {
          ...item,
          list: item.list.filter(filterFunction),
        };
      };
    },
    // 过滤大洲下国家列表为空的
    filterEmptyArea(item) {
      if (!item.list) {
        return false;
      }
      return item.list.length > 0;
    },
    filterCheckedCountry(item) {},
    getFilterTreeKeys(tree, filterValue) {
      const filteredTree = tree
        .map(this.mapCountry((i) => this.filterTreeNode(filterValue, i)))
        .filter(this.filterEmptyArea);
      const keys = this.getTreeKeys(filteredTree);
      return keys.filter(this.fliterGt10);
    },
    getTreeKeys(tree) {
      const keys = [];
      tree.forEach((i) => {
        keys.push(+i.country_id);
        i.list.forEach((_i) => keys.push(+_i.country_id));
      });
      return [...keys];
    },
    // 获取：左侧所有选中、半选中的 keys
    getAllCheckedKeys(treeRef) {
      if (!treeRef) {
        return [];
      }
      const checkedKeys = [
        // 左侧所有选中、半选中的 keys
        ...treeRef.getCheckedKeys(),
        ...treeRef.getHalfCheckedKeys(),
      ];
      return checkedKeys;
    },
    // 根据选中的 keys 生成右侧的树
    checkedKeysToTree(checkedKeys) {
      if (checkedKeys.length === 0) {
        return [];
      }

      const firstNodeKeys = checkedKeys.filter((key) => +key <= 10);
      const filterFirstNode = this.publishAreaTree.filter((i) =>
        firstNodeKeys.includes(+i.country_id)
      );
      const tree = filterFirstNode.map(
        this.mapCountry((_i) => checkedKeys.includes(+_i.country_id))
      );
      return tree;
    },
    transferToRight() {
      const tree = this.$refs.treeLeft;
      // 获取 keys: 左侧选中 + 右侧已生成
      const checkedKeys = this.getAllCheckedKeys(tree);
      // 获取 keys: 右侧 Tree 所有的
      const areaKeys = this.getTreeKeys(this.area);
      // 生成新的右侧 Tree
      const selectPublishAreaTree = this.checkedKeysToTree([
        ...checkedKeys,
        ...areaKeys,
      ]);
      // emit
      this.$emit("changeArea", selectPublishAreaTree);
    },
    // 删除选中的 keys
    deleteCheckedKeys(checkedKeys) {
      const areaList = [...this.area];
      const firstNodeKeys = checkedKeys.filter(this.fliterGl10);
      // 先过滤选中的国家
      const filterCheckedCountry = areaList.map(
        this.mapCountry((_i) => !checkedKeys.includes(+_i.country_id))
      );
      // 再过滤大洲下国家列表为空的
      const filterEmptyArea = filterCheckedCountry.filter(this.filterEmptyArea);
      return [...filterEmptyArea];
    },
    transferToLeft() {
      const tree = this.$refs.treeRight;
      // 获取所有选中的 keys
      const checkedKeys = tree.getCheckedKeys();
      // 删除选中的 keys
      const selectPublishAreaTree = this.deleteCheckedKeys(checkedKeys);

      // 清空选中，目的是为了通过 setCheckedKeys 清空 ElTree 组件的 data.store.defaultCheckedKeys
      // 否则会导致剩余的节点在 ElTree 更新的时候默认选中
      tree.setCheckedKeys([]);
      // 设置选中数为 0
      this.selectNumber.right = 0;

      // emit
      this.$emit("changeArea", selectPublishAreaTree);
    },
    fliterGt10(val) {
      return +val > 10;
    },
    fliterGl10(val) {
      return +val <= 10;
    },
    checkChangeLeft(clickNode, data) {
      // console.log(data.checkedKeys.filter((i) => +i > 10));
      this.selectNumber.left = data.checkedKeys.filter(this.fliterGt10).length;
    },
    checkChangeRight(clickNode, data) {
      const rightCheckNumer = data.checkedKeys.filter(this.fliterGt10).length;

      // 是否取消右侧全选box
      if (rightCheckNumer !== this.totalAreaRight) {
        this.selectAll.right = false;
      }
      // 更新右侧选中数
      this.selectNumber.right = rightCheckNumer;
    },
  },
  computed: {
    ...mapGetters(["publishAreaTree"]),
    totalArea() {
      let total = 0;
      this.publishAreaTree.forEach((item) => {
        total += item.list.length;
      });
      return total;
    },
    totalAreaRight() {
      let total = 0;
      this.area.forEach((item) => {
        total += item.list.length;
      });
      return total;
    },
  },
};
</script>

<style lang="less" scoped>
.publish-area-container {
  height: 500px;

  display: flex;
  justify-content: space-between;

  .transfer-left-container,
  .transfer-right-container {
    flex: 1;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    padding: 8px;

    .el-input {
      margin-bottom: 8px;
    }

    .select-all {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 44px;
      margin-bottom: 8px;
    }

    .el-tree {
      overflow-y: scroll;
      height: 400px;
    }
  }
  .transfer-center-container {
    align-self: center;
    margin: 0 12px;
  }
}
</style>
