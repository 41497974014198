<template>
  <div class="app-list-container">
    <div class="header">
      <el-col :span="4">
        <el-row type="flex">
          <el-col :span="12">
            <el-input v-model="app_id" placeholder="输入ID"></el-input>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4">
        <el-row type="flex">
          <el-col :span="12">
            <el-input v-model="app_name" placeholder="输入应用名称"></el-input>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
      <span class="label">状态：</span>
      <el-select v-model="status" @change="onChangeStatus">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <span class="label">发布：</span>
      <el-select v-model="publish" @change="onChangePublish">
        <el-option
          v-for="item in publishOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>

    <el-table :data="applist.items" border stripe>
      <el-table-column label="应用图标">
        <template slot-scope="scope">
          <img :src="scope.row.app_icon" width="80" />
        </template>
      </el-table-column>
      <el-table-column
        v-for="col in columns"
        :prop="col.id"
        :key="col.id"
        :label="col.label"
        :width="col.width"
        :formatter="col.formatter"
      ></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div class="table-options">
            <el-button size="mini" @click="showDetail(scope.row)"
              >详情</el-button
            >
            <el-button
              v-if="+scope.row.status === 1 && +scope.row.publish === 1"
              size="mini"
              type="primary"
              @click="setHot(scope.row)"
              >热门</el-button
            >
            <el-button
              v-if="+scope.row.status !== -2"
              size="mini"
              type="warning"
              @click="update(scope.row, -2)"
              >冻结</el-button
            >
            <el-button
              v-if="+scope.row.status !== -3"
              size="mini"
              type="danger"
              @click="update(scope.row, -3)"
              >删除</el-button
            >
            <el-button
              v-if="+scope.row.status === 1 && +scope.row.publish === 0"
              size="mini"
              type="success"
              @click="shelf(scope.row, 1)"
              >上架</el-button
            >
            <el-button
              v-if="+scope.row.status === 1 && +scope.row.publish === 1"
              size="mini"
              type="info"
              @click="shelf(scope.row, 0)"
              >下架</el-button
            >
            <el-button
              v-if="+scope.row.status === 1 && +scope.row.publish === 1"
              size="mini"
              type="success"
              @click="shelfArea(scope.row)"
              >上架地区</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="currentPage"
      :page-sizes="[30, 10, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="applist.total"
      background
      @size-change="sizeChange"
      @current-change="currentChange"
    ></el-pagination>

    <el-dialog
      title="详情"
      :visible.sync="detailDialog.visible"
      width="680px"
      @close="onClose"
    >
      <el-form :model="detailDialog.form" ref="form" label-width="120px">
        <el-form-item label="应用名称">
          <el-input v-model="detailDialog.form.app_name"></el-input>
        </el-form-item>
        <el-form-item label="应用名称(英文)">
          <el-input v-model="detailDialog.form.app_name_en"></el-input>
        </el-form-item>
        <el-form-item label="应用图标">
          <el-upload
            action="https://www.ddpurse.com/uploadImage"
            list-type="picture-card"
            :file-list="appIconList"
            :on-success="uploadIconSuccess"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="应用简介">
          <el-input
            v-model="detailDialog.form.app_info"
            type="textarea"
            maxlength="500"
            minlength="5"
            :autosize="{ minRows: 3 }"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="应用简介(英文)">
          <el-input
            v-model="detailDialog.form.app_info_en"
            type="textarea"
            maxlength="500"
            minlength="5"
            :autosize="{ minRows: 3 }"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="应用截图">
          <el-upload
            action="https://www.ddpurse.com/uploadImage"
            list-type="picture-card"
            :file-list="appImgList"
            :on-success="uploadImgSuccess"
            :on-change="uploadImgChange"
            :on-remove="uploadImgRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="应用分类">
          <el-select
            v-model="detailDialog.form.app_class_id"
            placeholder="请选择应用分类"
          >
            <el-option label="区块链浏览器" :value="1"></el-option>
            <el-option label="社交" :value="2"></el-option>
            <el-option label="新闻内容" :value="3"></el-option>
            <el-option label="交易所" :value="4"></el-option>
            <el-option label="金融服务" :value="5"></el-option>
            <el-option label="游戏娱乐" :value="6"></el-option>
            <el-option label="储存上链" :value="7"></el-option>
            <el-option label="搜索引擎" :value="8"></el-option>
            <el-option label="商家与服务" :value="9"></el-option>
            <el-option label="钱包" :value="10"></el-option>
            <el-option label="其他" :value="11"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="应用回调域名">
          <el-input v-model="detailDialog.form.merchant_domain"></el-input>
        </el-form-item>
        <el-form-item label="BSV收款地址">
          <el-input v-model="detailDialog.form.receive_address_bsv"></el-input>
        </el-form-item>
        <el-form-item label="BTC收款地址">
          <el-input v-model="detailDialog.form.receive_address_btc"></el-input>
        </el-form-item>
        <el-form-item label="ETH收款地址">
          <el-input v-model="detailDialog.form.receive_address_eth"></el-input>
        </el-form-item>
        <!-- prop="app_lang" -->
        <el-form-item label="应用语言">
          <el-select
            v-model="detailDialog.form.app_lang"
            multiple
            clearable
            size="medium"
            placeholder="请选择语言"
            @change="langChange"
          >
            <el-option
              v-for="item in langOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="网页型应用">
          <el-input v-model="detailDialog.form.get_web_url"></el-input>
        </el-form-item>
        <el-form-item label="iOS应用">
          <el-input v-model="detailDialog.form.get_ios_url"></el-input>
        </el-form-item>
        <el-form-item label="Android商店应用">
          <el-input v-model="detailDialog.form.get_android_url"></el-input>
        </el-form-item>
        <el-form-item label="Android APK包">
          <el-input v-model="detailDialog.form.get_apk_url"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="提示"
      :visible.sync="publishDialog.visible"
      width="30%"
      @close="onClosePublishDialog"
    >
      <span>{{ publishDialog.message }}</span>
      <span slot="footer">
        <el-button @click="onClosePublishDialog">取消</el-button>
        <el-button @click="onChangePublishArea">更改上架地区</el-button>
        <el-button type="primary" @click="onPublishAllarea">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="上架地区"
      :visible="publishAreaDialog.visible"
      width="680px"
      @close="handleClosePublishAreaDialog"
    >
      <div style="height: 500px">
        <publish-area
          v-if="publishAreaDialog.visible"
          :area="publishAreaDialog.area"
          @changeArea="handleChangeArea"
        />
      </div>
      <span slot="footer">
        <el-button @click="handleClosePublishAreaDialog">取消</el-button>
        <el-button type="primary" @click="onUpdateArea">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { updateApplication } from "@/api/developer";
import PublishArea from "./publishArea";
//import { delete } from 'vue/types/umd';

const JSONParseToArray = (data) => {
  if (!data) {
    return [];
  }

  try {
    return JSON.parse(data) || [];
  } catch (error) {
    return [];
  }
};

const LANG_MAP = {
  zh: "中文",
  en: "英文",
  ja: "日文",
};

export default {
  data() {
    return {
      columns: [
        { id: "id", label: "ID" },
        { id: "merchant_id", label: "商家ID" },
        { id: "app_id", label: "APP ID" },
        { id: "app_name", label: "应用名称" },
        { id: "app_info", label: "应用简介" },
        {
          id: "weight",
          label: "热门权重",
          formatter: (row) => {
            if (+row.weight > 0) {
              return <el-tag type="success">{row.weight}</el-tag>;
            }
            return <el-tag type="info">0</el-tag>;
          },
        },
        {
          id: "app_lang",
          label: "应用语言",
          formatter: (row) => {
            if (!row.app_lang || row.app_lang == "[]") {
              return "";
            }
            const list = JSONParseToArray(row.app_lang);
            return list ? list.map((lang) => LANG_MAP[lang]).join("，") : list;
          },
        },
        {
          id: "status",
          label: "状态",
          formatter: (row) => {
            switch (+row.status) {
              case 0:
                return <el-tag>待审核</el-tag>;
              case 1:
                return <el-tag type="success">审核通过</el-tag>;
              case -1:
                return <el-tag type="warning">审核不通过</el-tag>;
              case -2:
                return <el-tag type="danger">冻结</el-tag>;
              case -3:
                return <el-tag type="info">删除</el-tag>;
              default:
                return row.status;
            }
          },
        },
        {
          id: "publish",
          label: "应用墙",
          formatter: (row) => {
            if (+row.publish === 1) {
              return <el-tag type="success">已发布</el-tag>;
            } else if (+row.publish === 9) {
              return <el-tag type="warning">申请中</el-tag>;
            }
            return <el-tag type="info">未发布</el-tag>;
          },
        },
      ],
      langOptions: [
        { value: "zh", label: "中文" },
        { value: "en", label: "英文" },
        { value: "ja", label: "日文" },
      ],
      status: 1,
      app_id: "",
      app_name: "",
      statusOptions: [
        { label: "待审核", value: 0 },
        { label: "审核通过", value: 1 },
        { label: "审核失败", value: -1 },
        { label: "冻结", value: -2 },
        { label: "全部", value: "" },
      ],
      publish: "",
      publishOptions: [
        { label: "未发布", value: 0 },
        { label: "已发布", value: 1 },
      ],
      audit: false,
      detailDialog: {
        visible: false,
        form: {},
      },
      publishAreaDialog: {
        id: null,
        visible: false,
        area: [],
      },
      publishDialog: {
        message: "",
        visible: false,
        app: null,
      },
      currentPage: 1,
      pageSize: 30,
    };
  },
  mounted() {
    this.$store.dispatch("applist/getPublishArea");
    this.fetchData();
  },
  computed: {
    ...mapGetters(["applist", "publishAreaTree"]),
    appIconList() {
      if (this.detailDialog.form.app_icon) {
        return [
          {
            name: "app_icon",
            url: this.detailDialog.form.app_icon,
          },
        ];
      }
      return [];
    },

    appImgList() {
      let list = [];
      if (Array.isArray(this.detailDialog.form.app_img)) {
        list = this.detailDialog.form.app_img;
      } else {
        try {
          list = JSONParseToArray(this.detailDialog.form.app_img);

          if (!Array.isArray(list)) {
            list = [];
          }
        } catch (error) {
          console.log(error);
        }
      }

      return list.map((url, index) => ({
        url,
        name: "app_img_" + index,
      }));
    },
    appImgList2() {
      let list = [];
      if (Array.isArray(this.detailDialog.form.app_img)) {
        list = this.detailDialog.form.app_img;
      } else {
        try {
          list = JSONParseToArray(this.detailDialog.form.app_img);

          if (!Array.isArray(list)) {
            list = [];
          }
        } catch (error) {
          console.log(error);
        }
      }

      return list.map((url, index) => ({
        url,
        name: "app_img_" + index,
      }));
    },
  },
  methods: {
    fetchData() {
      let query = {
        app_id: this.app_id,
        app_name: this.app_name,
        status: this.status,
        publish: this.publish,
        page: this.currentPage,
        page_size: this.pageSize,
      };
      if (this.publish === "") {
        delete query.publish;
      }
      if (this.status === "") {
        delete query.status;
      }
      if (this.app_id === "") {
        delete query.app_id;
      }
      if (this.app_name === "") {
        delete query.app_name;
      }
      this.$store.dispatch("applist/getAppList", query);
    },
    showDetail(row) {
      console.log(row);
      let form = row;
      form.app_lang = JSONParseToArray(row.app_lang);
      this.detailDialog = {
        visible: true,
        form: form,
      };
    },
    onChangeStatus(val) {
      this.fetchData();
    },
    onChangePublish(val) {
      this.fetchData();
    },
    onClose() {
      this.detailDialog = {
        visible: false,
        form: {},
      };
    },
    // 点击【上架确认】弹窗的【修改上架地区】按钮
    onChangePublishArea() {
      this.shelfArea(this.publishDialog.app);
      this.onClosePublishDialog();
    },
    // 关闭【上架确认】弹窗
    onClosePublishDialog() {
      this.publishDialog = {
        visible: false,
        message: "",
        app: null,
      };
    },
    // 关闭【上架区域选择】弹窗
    handleClosePublishAreaDialog() {
      this.publishAreaDialog = {
        id: null,
        visible: false,
        area: [],
      };
    },
    // 审核、冻结、删除等 app.status 更新操作
    async update(row, status) {
      // 其他操作
      let optMsg = "操作";
      switch (status) {
        case 1:
          optMsg = "审核通过";
          break;
        case -1:
          optMsg = "审核不通过";
          break;
        case -2:
          optMsg = "冻结";
          break;
        case -3:
          optMsg = "删除";
          break;
      }
      this.$confirm(`${optMsg}【${row.app_name}】, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.updateApp(row.id, { status });
        })
        .catch((err) => console.log(err));
    },
    // 点击上架区域
    shelfArea(app) {
      const area = JSONParseToArray(app.publish_area);
      this.publishAreaDialog = {
        id: app.id,
        visible: true,
        area: area.map((i) => {
          return {
            ...i,
            country_id: this.getCid(i),
          };
        }),
      };
    },
    getCid(areaItem) {
      const res = this.publishAreaTree.find(
        (i) => i.area_en === areaItem.area_en
      );
      return +res.country_id;
    },
    handleChangeArea(area) {
      this.publishAreaDialog.area = area;
    },
    // 上架和下架
    async shelf(row, publish) {
      // 上架
      if (+publish === 1) {
        this.publishDialog = {
          message: `上架【${row.app_name}】，默认上架全部国家/地区，是否继续?`,
          app: row,
          visible: true,
        };
      }
      // 下架
      if (publish === 0) {
        this.$confirm(`下架【${row.app_name}】, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.updateApp(row.id, { publish, publish_area: "" });
        });
      }
    },
    // 设置热门
    setHot(row) {
      this.$prompt(
        "请输入热门权重，权重越大排名越靠前",
        `应用【${row.app_name}】`,
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputValue: row.weight || "",
        }
      )
        .then(({ value }) => {
          this.$store.dispatch("applist/update", {
            id: row.id,
            data: { weight: +value },
          });
        })
        .catch((err) => console.log(err));
    },
    sizeChange(size) {
      this.pageSize = size;
      this.fetchData();
    },
    currentChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    search() {
      this.currentPage = 1;
      this.fetchData();
    },
    /**
     * 生成要修改地区的参数 publish_area
     * 1、JSON.stringify
     * 2、数据接口移除第一层的 country_id
     */
    generatePublishAreaString(area) {
      const res = area.map((i) => {
        return {
          area: i.area,
          area_en: i.area_en,
          list: i.list,
        };
      });
      return JSON.stringify(res);
    },
    // 更新 app 上架地区
    async onUpdateArea() {
      const data = {
        publish_area: this.generatePublishAreaString(
          this.publishAreaDialog.area
        ),
        publish: 1, // 修改地区默认上架
      };
      const { success } = await this.updateApp(this.publishAreaDialog.id, data);
      if (success) {
        this.handleClosePublishAreaDialog();
      }
    },
    // 查看 app 详情，并确认修改
    async onSubmit() {
      let data = this.detailDialog.form;
      delete data.status;
      delete data.publish;
      delete data.weight;
      if (Array.isArray(data.app_img)) {
        data.app_img = JSON.stringify(data.app_img);
      }
      if (data.app_lang) {
        data.app_lang = JSON.stringify(data.app_lang);
      }
      const { success } = await this.updateApp(this.detailDialog.form.id, data);
      if (success) {
        this.detailDialog = {
          visible: false,
          form: {},
        };
      }
    },
    // 上架并设置默认上架所有地区
    async onPublishAllarea() {
      const params = {
        publish: 1,
        publish_area: this.generatePublishAreaString(this.publishAreaTree),
      };

      const { success } = await this.updateApp(
        this.publishDialog.app.id,
        params
      );

      if (success) {
        this.onClosePublishDialog();
      }
    },
    // 更新 app
    async updateApp(id, params) {
      try {
        const { data } = await updateApplication(id, params);
        const { msg = "", code } = data;
        const success = +code === 0;
        this.$message({
          message: msg,
          duration: 3000,
          type: +code === 0 ? "success" : "error",
        });
        if (success) {
          this.fetchData();
        }
        return {
          success,
          msg,
        };
      } catch (error) {
        return {
          success: false,
          msg: "网络错误，请稍后再试",
        };
      }
    },
    uploadIconSuccess(data) {
      if (+data.code) {
        this.$message({
          message: "上传失败",
          duration: 3000,
          type: "waring",
        });
      } else {
        this.detailDialog.form.app_icon = data.data;
      }
    },
    uploadImgChange(file, fileList) {
      console.log(file, fileList);
    },
    uploadImgRemove(file, fileList) {
      let list = this.handleAppImg(this.detailDialog.form.app_img);
      this.detailDialog.form.app_img = list.filter((i) => i !== file.url);
    },
    uploadImgSuccess(data) {
      if (+data.code) {
        this.$message({
          message: "上传失败",
          duration: 3000,
          type: "waring",
        });
      } else {
        let list = this.handleAppImg(this.detailDialog.form.app_img);
        list.push(data.data);
        this.detailDialog.form.app_img = list;
      }
    },
    handleAppImg(app_img) {
      if (Array.isArray(app_img)) {
        return [...app_img];
      } else {
        try {
          const _app_img = JSONParseToArray(app_img);
          return this.handleAppImg(_app_img);
        } catch (error) {
          return [];
        }
      }
    },
    langChange(val) {
      // console.log(val);
      this.detailDialog.form.app_lang = val;
    },
  },
  components: {
    PublishArea,
  },
};
</script>

<style lang="less" scoped>
.app-list-container {
  margin: 20px;

  .header {
    margin-bottom: 20px;
    text-align: right;
    margin-right: 20px;
    font-size: 14px;

    > .label {
      margin-right: 12px;
    }
  }

  .table-options {
    display: flex;
    align-items: center;
    flex-direction: column;
    .el-button {
      margin: 4px 0;
    }
  }
}
</style>
